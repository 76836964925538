import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer>
      <p>
        This website was built by Angel, Brooke, Caitlin, and Hannah for Grace
        Shopper
      </p>
    </footer>
  );
};

export default Footer;
